// Auth
const LOGIN = '/merchants';

// Shops
const GET_SHOPS = '/services';
const GET_SHOP = (id: string) => `/services/${id}`;
const CREATE_SERVICE = `/services`;
const UPDATE_SHOP = (id: string) => `/services/${id}`;
const DELETE_SHOP = (id: string) => `/services/${id}/delete`;
const UPDATE_SERVICE_ADDRESS = (id: string) => `/services/${id}/update-address`;

//Products
const GET_PRODUCTS = (shopId: string) => `/products/${shopId}`;
const GET_PRODUCT = (shopId: string, id: string) => `/products/${shopId}/${id}`;
const CREATE_PRODUCT = (shopId: string) => `/products/${shopId}/create`;
const UPDATE_PRODUCT = (shopId: string, id: string) =>
  `/products/${shopId}/${id}/update`;
const DELETE_PRODUCT = (shopId: string, id: string) =>
  `/products/${shopId}/${id}/delete`;

//Import CSV
const IMPORT_CSV = (shopId: string) => `/products/${shopId}/import`;

// Config
const GET_CONFIG = (shopId: string) => `/merchants/${shopId}/config`;

// Orders
const GET_ORDERS = (shopId: string) => `/orders/${shopId}/list?`;
const GET_CART_LIST = (shopId: string) => `/orders/${shopId}/cart`;
const GET_ORDER = (shopId: string, id: string) => `/orders/${shopId}/${id}`;
const UPDATE_ORDER_STATUS = (shopId: string, id: string) =>
  `/orders/${shopId}/${id}/update-status`;

export {
  LOGIN,
  GET_PRODUCTS,
  GET_PRODUCT,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  GET_SHOP,
  GET_SHOPS,
  CREATE_SERVICE,
  UPDATE_SHOP,
  DELETE_SHOP,
  UPDATE_SERVICE_ADDRESS,
  IMPORT_CSV,
  GET_CONFIG,
  GET_ORDERS,
  GET_CART_LIST,
  GET_ORDER,
  UPDATE_ORDER_STATUS
};

import { configModel } from 'entities/Config/model';
import { ordersModel } from 'entities/Orders/model';
import { productsModel } from 'entities/Products/model';
import { useEffect } from 'react';

export const useInitRequests = () => {
  useEffect(() => {
    configModel.config.events.getConfig();
    productsModel.products.events.getProducts();
    ordersModel.list.events.getOrders();
    ordersModel.cart.events.getOrders();
  }, []);
};

import { createEvent, createStore, sample } from 'effector';
import { SELECTED_SERVICE_ID_CACHE_KEY } from '../config';
import { endPoints } from 'shared/config/endPoints';
import { redirect } from 'shared/lib/redirect';
import { ISelectedService } from '../types';
import { IService } from 'shared/types/api/shops';

const resetService = createEvent();
const setService = createEvent<IService>();
const updateService = createEvent<IService>();
const $service = createStore<ISelectedService>(
  JSON.parse(localStorage.getItem(SELECTED_SERVICE_ID_CACHE_KEY)) ?? null,
);

sample({
  clock: [setService, updateService],
  fn: (value) => {
    if (value) {
      const selectedService = {
        id: value.id,
        name: value.name,
        ...(value.latitude &&
          value.longitude && {
            coords: [value.latitude, value.longitude],
          }),
        ...(value.address && { address: value.address }),
      };

      localStorage.setItem(
        SELECTED_SERVICE_ID_CACHE_KEY,
        JSON.stringify(selectedService),
      );

      return selectedService;
    }

    localStorage.removeItem(SELECTED_SERVICE_ID_CACHE_KEY);
    return null;
  },
  target: $service,
});

sample({
  clock: setService,
  filter: Boolean,
  fn: () => endPoints.PRODUCTS,
  target: redirect,
});

sample({
  clock: resetService,
  fn: () => null,
  target: setService,
});

export const selectedService = {
  stores: {
    $service,
  },
  events: {
    resetService,
    updateService,
    setService,
  },
};

import { authModel } from 'entities/Auth/model';
import { confirmModalModel } from 'features/ConfirmModal/model';
import { endPoints } from 'shared/config/endPoints';

export const menuList = [
  {
    name: 'Click Bots',
    routes: [
      {
        name: 'Товары',
        endPoint: endPoints.PRODUCTS,
      },
      {
        name: 'Импорт CSV',
        endPoint: endPoints.CSV,
      },
      {
        name: 'Заказы',
        endPoint: endPoints.ORDERS,
      },
      {
        name: 'Корзина',
        endPoint: endPoints.CART,
      },
      {
        name: 'Тестирование',
        endPoint: endPoints.TEST,
      },
      {
        name: 'Настройки',
        endPoint: endPoints.SETTINGS,
      },
      {
        name: 'Выйти',
        fn: () => {
          confirmModalModel.modal.setVisible({
            isOpen: true,
            params: {
              title: 'profile.logoutConfirmTitle',
              onConfirm: () => {
                authModel.logout.events.logoutAccount();
                confirmModalModel.modal.resetModal();
              },
            },
          });
        },
        endPoint: null,
      },
    ],
  },
];

export enum endPoints {
  LOGIN = '/login',
  DEFAULT_PAGE = '/',

  SHOPS = '/shops',

  PRODUCTS = '/products',
  PRODUCT_CREATE = `${PRODUCTS}/create`,
  PRODUCT_EDIT = `${PRODUCTS}/edit/:id`,
  PRODUCT_VIEW = `${PRODUCTS}/view/:id`,

  CSV = '/csv',
  TEST = '/test',
  SETTINGS = '/settings',

  ORDERS = '/orders',
  ORDER = `${ORDERS}/:id`,
  CART = '/cart',
  CART_ORDER = `${CART}/:id`,
}

import React, { FC } from 'react';
import { useUnit } from 'effector-react';
import { productsModel } from 'entities/Products/model';
import { sharedCss } from 'shared/css';
import { BoxHeader } from 'shared/ui/molecules/BoxHeader';
import { Table } from 'shared/ui/molecules/Table';
import { ITableDataItem } from 'shared/ui/molecules/Table/types';
import { productsCss } from './style';
import { IProduct } from 'shared/types/api/products';
import { Button } from 'shared/ui/atoms/Button';
import { useHistory } from 'react-router-dom';
import { endPoints } from 'shared/config/endPoints';
import { confirmModalModel } from 'features/ConfirmModal/model';
import { productsPageModel } from './model';

export const Products: FC = () => {
  const history = useHistory();
  const [list] = useUnit([productsModel.products.stores.$displayList]);
  const data: ITableDataItem<IProduct> = {
    headRows: [
      { name: 'ID', hasSort: false },
      { name: 'Фото' },
      { name: 'Название', hasSort: false },
      { name: 'Описание' },
      { name: 'Цена' },
    ],
    bodyRows: list,
  };

  const onClickCreate = () => {
    history.push(endPoints.PRODUCT_CREATE);
  };

  const onViewProduct = (id: string) => {
    history.push(endPoints.PRODUCT_VIEW.replace(':id', id));
  };

  const onEditProduct = (id: string) => {
    history.push(endPoints.PRODUCT_EDIT.replace(':id', id));
  };

  const onDeleteProduct = (id: string) => {
    confirmModalModel.modal.setVisible({
      isOpen: true,
      params: {
        title: 'products.deleteTitle',
        onConfirm: () => {
          productsPageModel.productActions.events.deleteProduct(id);
          confirmModalModel.modal.resetModal();
        },
      },
    });
  };

  const onClickSort = (key: string) => {};

  return (
    <div className={`${sharedCss.pageContainer} ${productsCss.container}`}>
      <BoxHeader
        title="Товары"
        rightContent={<Button onClick={onClickCreate}>Создать товар</Button>}
      />
      <Table<IProduct>
        data={data}
        keys={[
          { name: 'id', hasCopy: true },
          { name: 'image', isImage: true },
          'name',
          'description',
          'price',
        ]}
        mapKey="id"
        actions={{
          onView: onViewProduct,
          onEdit: onEditProduct,
          onDelete: onDeleteProduct,
        }}
        onClickSort={onClickSort}
      />
    </div>
  );
};
